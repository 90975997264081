import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import React, { createContext, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { setUserAction, setUserDataAction } from "../loginReducer";
import { SmallLogoWithDashboard } from "../logo/SmallLogo";
import LogoutWidget from "../logout/LogoutWidget";
import { UserData } from "../model/user";
import { ApplicationState } from "../reducers";
import { deleteMode, getModeUsage, ModeStatistics } from "../services/adminService";
import NotificationOverlay from "../status/NotificationOverlay";
import { Grid, WrappingRow } from "../layout/Row";
import ActionButton from "../button/ActionButton";

const Header = (props: Props) => {
  return (
    <>
      <div className={"Dashboard-header"}>
        <SmallLogoWithDashboard fontSizeRem={1.625} />
        <LogoutWidget {...props} />
      </div>
      <NotificationOverlay />
    </>
  );
};

const Details = ({ mode, jwtToken }: { mode: ModeStatistics; jwtToken: string }) => {
  const [loading, setLoading] = React.useState(false);
  const refresh = useApiTrigger();
  const margin = { margin: "0.25rem 0" };
  if (!mode.organizations.length) {
    return (
      <>
        <h4 style={{ ...margin, opacity: 0.5 }}>Not in use</h4>
        <ActionButton
          text="DELETE"
          onClick={() => {
            setLoading(true);
            deleteMode(jwtToken, mode.name).then(() => {
              refresh();
              setLoading(false);
            });
          }}
          style={{ marginLeft: 0, opacity: loading ? 0.5 : 1 }}
        />
      </>
    );
  }
  return (
    <>
      <h4 style={margin}>Used by</h4>
      {mode.organizations.map((organisation) => (
        <p style={margin}>{organisation.name}</p>
      ))}
      {!mode.exists && <h4 style={{ color: "red" }}>Mode does not exist</h4>}
    </>
  );
};

const ApiTriggerContext = createContext(() => {});
export const useApiTrigger = () => useContext(ApiTriggerContext);

const Mode = ({ mode, jwtToken }: { mode: ModeStatistics; jwtToken: string }) => {
  if (!mode.organizations) {
    return null;
  } else {
  }
  return (
    <div className={"Widget-item"}>
      <h3>{mode.name}</h3>
      <Details mode={mode} jwtToken={jwtToken} />
    </div>
  );
};

const Modes = ({ modes, jwtToken }: { modes: ModeStatistics[]; jwtToken: string }) => {
  return (
    <Grid>
      {modes.map((mode, index) => (
        <Mode key={index} mode={mode} jwtToken={jwtToken} />
      ))}
    </Grid>
  );
};

const ModePage = (props: Props) => {
  const [modes, setModes] = React.useState<ModeStatistics[]>([]);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const refresh = () => {
    if (props.jwtToken) {
      getModeUsage(props.jwtToken)
        .then((modes) => {
          setModes(modes);
        })
        .catch((response) => {
          setError("Couldn't get mode usage");
        });
    }
  };
  useEffect(() => {
    refresh();
  }, [props.jwtToken]);
  return (
    <div className="Dashboard">
      <Header {...props} />
      {props.jwtToken ? (
        <ApiTriggerContext.Provider value={refresh}>
          <Modes modes={modes} jwtToken={props.jwtToken} />
        </ApiTriggerContext.Provider>
      ) : (
        <div />
      )}
    </div>
  );
};

interface StateToProps {
  jwtToken: string | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken,
  user: state.login.user,
  userData: state.login.userData,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser: setUserAction,
      setUserData: setUserDataAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(ModePage);
