import { COMMANDER_URL } from "../dashboard/Dashboard";
import { getLoginInfo, getLoginInfoByEmail } from "../services/adminService";

export const goToAdminCommanderByEmail = (email: string, jwtToken: string) => {
  goToUrlByEmail(email, jwtToken, "https://triangula-admin.firebaseapp.com");
};

export const goToCommanderByEmail = (email: string, jwtToken: string) => {
  goToUrlByEmail(email, jwtToken, COMMANDER_URL);
};

export const goToAdminCommanderByUserId = (userId: string, jwtToken: string) => {
  goToUrlByUserId(userId, jwtToken, "https://triangula-admin.firebaseapp.com");
};

export const goToCommanderByUserId = (userId: string, jwtToken: string) => {
  goToUrlByUserId(userId, jwtToken, COMMANDER_URL);
};

const goToUrlByEmail = (email: string, jwtToken: string, url: string) => {
  getLoginInfoByEmail(email, jwtToken)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data;
        const win = window.open(`${url}?token=${data.jwtToken}`, "_self");
        // @ts-ignore
        win.focus();
      } else {
        alert("Couldn't get login credentials");
      }
    })
    .catch((response) => alert("Couldn't get login credentials"));
};

const goToUrlByUserId = (userId: string, jwtToken: string, url: string) => {
  getLoginInfo(userId, jwtToken)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data;
        const win = window.open(`${url}?token=${data.jwtToken}`, "_self");
        // @ts-ignore
        win.focus();
      } else {
        alert("Couldn't get login credentials");
      }
    })
    .catch((response) => alert("Couldn't get login credentials"));
};
